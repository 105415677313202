
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.rating-v1 {
  @include media(xl) {
    display: grid;
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
}

// Text block
.rating-v1__text {
  margin-bottom: 48px;

  @include media(md) {
    display: grid;
    grid-column-gap: 32px;
    grid-template-rows: auto 1fr;
    grid-template-columns: 328px 1fr;
  }

  @include media(lg) {
    grid-column-gap: 56px;
    grid-template-columns: 424px 1fr;
    margin-bottom: 56px;
  }

  @include media(xl) {
    display: block;
    margin-bottom: 0;
  }
}

.rating-v1__title {
  margin-bottom: 32px;

  @include media(md) {
    grid-template-rows: 1/-1;
    grid-template-columns: 1/2;
    align-self: flex-start;
    margin-bottom: 0;
  }

  @include media(xl) {
    margin-bottom: 56px;
  }
}

.rating-v1__stats {
  @include ulres;

  margin-bottom: 24px;

  @include media(md) {
    grid-row: 1/2;
    grid-column: 2/-1;
  }

  @include media(lg) {
    width: 368px;
  }

  @include media(xl) {
    margin-bottom: 32px;
  }
}

.rating-v1__stat {
  &:not(:last-child) {
    margin-bottom: 24px;

    @include media(xl) {
      margin-bottom: 32px;
    }
  }
}

.rating-v1__stat-number,
.rating-v1__stat-text {
  display: block;
}

.rating-v1__source {
  margin-bottom: 0;

  @include media(md) {
    grid-row: 2/-1;
    grid-column: 2/-1;
  }

  @include media(lg) {
    width: 368px;
  }
}

// Slider block
.rating-v1__block {
  padding-top: 40px;
  border-radius: 16px;
  background-color: var(--bg-secondary-color);

  @include media(lg) {
    padding-top: 72px;
  }

  @include media(xl) {
    display: flex;
    flex-direction: column;
  }
}

.rating-v1__count {
  max-width: 280px;
  margin: 0 auto 12px;
  text-align: center;

  @include media(sm-max) {
    padding: 0 20px;
  }

  @include media(lg) {
    max-width: 472px;
    margin-bottom: 40px;
  }

  br {
    @include media(lg) {
      display: none;
    }
  }
}

.rating-v1__slider {
  @include z-index(base);

  margin-bottom: 12px;

  @include media(lg) {
    margin-bottom: 40px;
  }
}

.rating-v1__controls {
  @include media(lg) {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    width: 472px;
    transform: translate(-50%, -50%);
  }
}

.rating-v1__arrow {
  @include media(md-max) {
    display: none;
  }
}

.rating-v1__slider-wrapper {
  position: relative;
  width: 280px;
  margin: 0 auto;
  padding: 32px 24px;
  overflow-x: hidden;

  &::before,
  &::after {
    @include z-index(absolute, base, raised);

    top: 0;
    display: block;
    width: 24px;
    height: 100%;
    content: '';
  }

  &::before {
    left: 0;
    background: linear-gradient(
      90deg,
      var(--bg-secondary-color) 0%,
      rgba(var(--bg-secondary-color-rgb), 0) 100%
    );
  }

  &::after {
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(var(--bg-secondary-color-rgb), 0) 0%,
      var(--bg-secondary-color) 100%
    );
  }
}

.rating-v1__list {
  @include ulres;

  display: flex;
}

.rating-v1__review {
  display: grid;
  flex-shrink: 0;
  grid-gap: 8px 28px;
  grid-template-rows: 20px 24px 1fr;
  grid-template-columns: auto 1fr;
  width: 100%;

  &:not(:last-child) {
    margin-right: 100px;
  }
}

.rating-v1__rating {
  grid-row: 1/-1;
  grid-column: 1/2;
  align-self: flex-start;
  font-weight: 600;
  font-size: 52px;
  line-height: 60px;
}

.rating-v1__logo {
  grid-row: 1/2;
  grid-column: 2/-1;
  max-height: 20px;
  margin-right: auto;
}

.rating-v1__stars {
  position: relative;
  display: inline-block;
  grid-row: 2/3;
  grid-column: 2/-1;
  color: var(--accent-warning-color);
}

.rating-v1__stars-max {
  fill: none;
}

.rating-v1__stars-filled {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  fill: var(--accent-warning-color);
}

.rating-v1__reviews-count {
  grid-row: 3/-1;
  grid-column: 2/-1;
  align-self: flex-start;
}

.rating-v1__pic {
  margin: 0 auto;

  @include media(sm-max) {
    position: relative;
    padding-top: calc(100% / 19 * 5.59);
    overflow: hidden;
  }

  @include media(md-max) {
    max-width: 280px;
    max-height: 96px;
  }

  @include media(lg) {
    width: 472px;
    height: 162px;
  }

  @include media(xl) {
    margin-top: auto;
  }

  img {
    @include media(sm-max) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}
